const parseLaureate = (entry) => {
  return {
    slug: entry.full_slug,
    name: entry.content.name,
    year: entry.content.year,
    type: entry.content.type,
    image: entry.content.list_image,
    id: entry.uuid,
  }
}

module.exports = parseLaureate
