const { toDate } = require("date-fns-tz")

const parseVideo = (entry) => {
  return {
      slug: entry.full_slug,
      id: entry.uuid,
      type: entry.content.type,
      youtube_id: entry.content.youtube_id,
      label: entry.content.label,
      title: entry.content.title,
      date: toDate(entry.content.date, { timeZone: 'Europe/London' }),
      image: entry.content.image,
      description: entry.content.description,
      location: entry.content.location,
      duration: entry.content.duration,
  }
}

module.exports = parseVideo
