import React from 'react'
import SbEditable from 'storyblok-react'
import StoryblokClient from 'storyblok-js-client'
import isNode from 'is-node'
import sha1 from 'js-sha1'

import parseVideo from 'src/parsers/parseVideo.js'
import parseLaureate from 'src/parsers/parseLaureate.js'
import parseArticle from 'src/parsers/parseArticle.js'
import Components from 'src/storyblok/Components'

import * as styles from './editor.module.scss'

const getQueryParam = (param: string): string => {
  if (!isNode) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param) as string
  } else {
    return ''
  }
}

const getParam = function (val: any) {
  let result = ''
  let tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

// loadStory is triggered on init and on input change in storyblok
// if the story uses references in storyblok, they will be fetched on every loadStory
//
// if the story uses some kind of post data where we fetch all of a type, that data will be
// fetched on init with fetchPostsBySlug and added to extraContent

class StoryblokEntry extends React.Component {
  storyblok: any
  editMode = false

  constructor(props: any) {
    super(props)

    this.validateUser()

    this.state = { story: null, extraContent: {}, header: { content: {} } }
  }

  validateUser = () => {
    const validationString =
      getQueryParam('_storyblok_tk[space_id]') +
      ':' +
      getQueryParam('_skey') +
      ':' +
      getQueryParam('_storyblok_tk[timestamp]')

    let validationToken = sha1.create()
    validationToken.update(validationString)
    validationToken.hex()

    if (
      getQueryParam('_storyblok_tk[token]') == validationToken &&
      parseInt(getQueryParam('_storyblok_tk[timestamp]')) >
        Math.floor(Date.now() / 1000) - 3600
    ) {
      // you're in the edit mode.
      this.editMode = true
    }
  }

  loadStoryblokBridge = (cb: any) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    if (this.editMode) {
      script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${getQueryParam(
        '_skey'
      )}`
      script.onload = cb
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }

  componentDidMount() {
    if (this.editMode) {
      this.storyblok = new StoryblokClient({
        accessToken: getQueryParam('_skey'),
        cache: {
          clear: 'auto',
          type: 'memory',
        },
      })
      this.loadStoryblokBridge(() => {
        this.initStoryblokEvents()
      })
    }
  }

  fetchPostsBySlug(slug: string, key: string, parser?: any) {
    this.storyblok
      .get('cdn/stories', {
        by_slugs: slug,
        is_startpage: 0,
        sort_by: 'published_at',
        per_page: 100,
      })
      .then((response: any) => {
        if (response && response.data && response.data.stories) {
          const extraContent = this.state.extraContent
          extraContent[key] = parser
            ? response.data.stories.map((story: any) => parser(story))
            : response.data.stories
          this.setState({ extraContent: extraContent })
        }
      })
  }

  fetchReferences(story: any, key: string, parser?: any) {
    if (
      story &&
      story.content &&
      story.content[key] &&
      story.content[key].length > 0
    ) {
      this.storyblok
        .get('cdn/stories', {
          by_uuids: story.content[key].join(','),
        })
        .then((response: any) => {
          if (response && response.data && response.data.stories) {
            story.content[key] = parser
              ? response.data.stories.map((story: any) => parser(story))
              : response.data.stories
          }
          this.setState({ story: story })
        })
    }
  }

  loadAllRefs(story: any) {
    if (story) {
      if (story.content) {
        if (story?.content?.videos) {
          this.fetchReferences(story, 'videos', parseVideo)
        }
      }

      this.setState({ story: story })
    }
  }

  loadStory(init?: boolean) {
    this.storyblok
      .get(`cdn/stories/${getParam('path')}`, {
        version: 'draft',
      })
      .then((response: any) => {
        const { data } = response
        if (init) {
          const include_laureates = [
            'page_start',
            'page_laureates_landing',
            'page_laureate',
            'page_press',
          ]
          const include_news = ['page_start']
          const include_press_releases = ['page_press']
          const include_videos = ['page_videos_landing']

          const component = data?.story?.content?.component
          if (include_news.includes(component)) {
            this.fetchPostsBySlug('news/*', 'articles', parseArticle)
          }
          if (include_laureates.includes(component)) {
            this.fetchPostsBySlug('laureates/*', 'laureates', parseLaureate)
          }
          if (include_press_releases.includes(component)) {
            this.fetchPostsBySlug('press/releases/*', 'articles', parseArticle)
          }
          if (include_videos.includes(component)) {
            this.fetchPostsBySlug('polar-talks/videos/*', 'videos', parseVideo)
          }
        }
        if (data && data.story) {
          this.setState({ story: data.story })
          this.loadAllRefs(data.story)
        }
      })
  }

  // loadHeader(lang: string) {
  //   const language = lang === 'default' ? '' : lang + '/'
  //   window.storyblok.get(
  //     {
  //       slug: `${language}global-navi`,
  //       version: 'draft',
  //     },
  //     (data: any) => {
  //       this.setState({ header: data.story })
  //     }
  //   )
  // }

  // loadFooter(lang: string) {
  //   const language = lang === 'default' ? '' : lang + '/'
  //   window.storyblok.get(
  //     {
  //       slug: `${language}global-navi`,
  //       version: 'draft',
  //     },
  //     (data: any) => {
  //       this.setState({ header: data.story })
  //     }
  //   )
  // }

  initStoryblokEvents() {
    this.loadStory(true)

    const sb = window.storyblok

    sb.on(['change', 'published'], (payload) => {
      this.loadStory()
    })

    sb.on('input', (payload: any) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )

        const story = payload.story
        this.loadAllRefs(story)
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    const { story, extraContent } = this.state

    if (story == null) {
      return <div />
    }
    let content = story.content

    content = {
      ...content,
      ...extraContent,
    }

    return (
      <SbEditable content={content}>
        <div className={styles.editor}>
          {React.createElement(Components(story.content.component), {
            storyID: story.uuid,
            tags: story.tag_list,
            name: story.name,
            slug: story.slug,
            isStartPage: story.is_startpage,
            full_slug: story.full_slug,
            date: story.published_at,
            blok: content,
          })}
        </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
