const { toDate } = require('date-fns-tz')

const parseArticle = (entry) => {
  return {
    slug: entry.full_slug,
    title: entry.content.title,
    summary: entry.content.summary || '',
    content: entry.content.content,
    test: entry.content.date,
    date: toDate(entry.content.date, { timeZone: 'Europe/London' }),
    type: entry.content.type,
    media: entry.content.media,
    id: entry.uuid,
  }
}

module.exports = parseArticle
